  let currentActiveIndex = 0; // Initialize to 0 for the first element

    // Get all elements with the class "switcher--container-mini"
    const switcherContainersMini = document.querySelectorAll('.switcher--container-mini');
    const switcherItems = document.querySelectorAll('.switcher__item');

    // Add click event listeners to all switcherContainersMini elements
    switcherContainersMini.forEach((container, index) => {
      container.addEventListener('click', function() {
        if (currentActiveIndex !== index) {
          // Remove the 'active' class from the previously active element
          switcherContainersMini[currentActiveIndex].classList.remove('active');
          switcherItems[currentActiveIndex].classList.remove('black-gradient');
        }

        // Add the 'active' class to the clicked switcher--container-mini
        container.classList.add('active');

        // Add the 'black-gradient' class to the corresponding switcher__item
        switcherItems[index].classList.add('black-gradient');

        // Update the current active index
        currentActiveIndex = index;
      });
    });


    const themeToggle = document.getElementById('theme-toggle');
const body = document.body;

themeToggle.addEventListener('change', () => {
    if (themeToggle.checked) {
        // Dark theme
        body.classList.add('dark-theme');
    } else {
        // Light theme
        body.classList.remove('dark-theme');
    }
});

// Initialize theme based on user preference (if set)
if (localStorage.getItem('theme') === 'dark') {
    themeToggle.checked = true;
    body.classList.add('dark-theme');
} else {
    themeToggle.checked = false;
    body.classList.remove('dark-theme');
}

